import React from 'react';
import { navigate } from 'gatsby-link';
import ContactPageTemplate from '../../components/ContactPageTemplate';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    // console.log({ ...this.state });
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <ContactPageTemplate>
        <form
          name="contact"
          method="post"
          action="/contact/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          <div className="field">
            <label className="label" htmlFor={'name'}>
              Name
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'name'}
                onChange={this.handleChange}
                id={'name'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={'email'}>
              Email
            </label>
            <div className="control">
              <input
                className="input"
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                id={'email'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={'phone'}>
              Phone
            </label>
            <div className="control">
              <input
                className="input"
                type={'tel'}
                name={'phone'}
                onChange={this.handleChange}
                id={'phone'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={'subject'}>
              Subject
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'subject'}
                onChange={this.handleChange}
                id={'subject'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">City</label>
            <div className="control">
              <div className="select">
                <select required name={'city'} onChange={this.handleChange}>
                  <option value="">
                    Please select a city in our service area
                  </option>
                  <option>Ancaster</option>
                  <option>Burlington</option>
                  <option>Cambridge</option>
                  <option>Dundas</option>
                  <option>Grimsby</option>
                  <option>Hamilton</option>
                  <option>Oakville</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={'message'}>
              Message
            </label>
            <div className="control">
              <textarea
                className="textarea"
                name={'message'}
                onChange={this.handleChange}
                id={'message'}
                required={true}
              />
            </div>
          </div>
          <div className="field has-text-centered">
            <button
              className="button is-primary is-uppercase is-call-to-action"
              type="submit"
            >
              Send
            </button>
          </div>
        </form>
      </ContactPageTemplate>
    );
  }
}
