import React from 'react';
import Layout from './Layout';
import BannerHeader from './BannerHeader';
import LearnHero from './LearnHero';
import ReviewBar from './ReviewBar';
import TestimonialCarousel from './TestimonialCarousel';
import SEO from './SEO';

import headerImage from '../img/banner-moving-boxes.jpg';

const ContactPageTemplate = ({ children }) => {
  const emailConvert = () => {
    if (typeof window !== `undefined`) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-817271415/uGlDCP6CrN0BEPek2oUD',
      });
    }
  };

  const phoneConvert = () => {
    if (typeof window !== `undefined`) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-817271415/GFRGCOaCrN0BEPek2oUD',
      });
    }
  };

  return (
    <Layout>
      <SEO title="Contact Us" pathname="/contact" />
      <BannerHeader
        title="Connect with us"
        subtitle="You can find out more by giving us a call"
        image={headerImage}
      />
      <section className="section">
        <div className="container">
          <div className="content">
            <div className="columns">
              <div className="column is-6-tablet is-12-mobile">{children}</div>
              <div className="column is-6-tablet is-12-mobile">
                <h1 className="is-uppercase">Thank you for visiting</h1>
                <p>
                  Thank you for visiting Organized Transitions. If you are
                  interested in any of our services or would like a
                  consultation, please connect with us through our form or call
                  the number below.
                </p>
                <p>
                  <strong>phone:</strong>&nbsp;&nbsp;&nbsp;
                  <a href="tel:+19055991816" onClick={phoneConvert}>
                    905.599.1816
                  </a>
                  <br />
                  <strong>email:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    title=""
                    href="mailto:info@organizedtransitions.ca"
                    onClick={emailConvert}
                  >
                    info@organizedtransitions.ca
                  </a>
                  <br />
                  <strong>web:</strong>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;www.organizedtransitions.ca
                </p>
                <div className="has-text-centered">
                  <a
                    href="tel:+19055991816"
                    className="button is-primary is-uppercase is-call-to-action"
                  >
                    Call Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LearnHero />
      <TestimonialCarousel />
      <ReviewBar />
    </Layout>
  );
};

export default ContactPageTemplate;
